<template>
    <div>
        <el-form :inline="true" ref="form" :model="query" class="demo-form-inline logs-form">
            <el-form-item label="文档名称">
                <el-input v-model="query.keyword" placeholder="请输入文档名称"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">查询</el-button>
            </el-form-item>
        </el-form>

        <div class="table-box">
            <el-table
                v-loading='loading'
                :data="viewer"
                border
                style="width: 100%">
                <el-table-column
                type="index"
                label="序号"
                align="center"
                width="100">
                </el-table-column>
                <el-table-column
                prop="name"
                label="文档名称">
                </el-table-column>
                <el-table-column
                prop="ownerName"
                label="所有者"
                align="center">
                </el-table-column>
                <el-table-column
                width="100"
                prop="suffix"
                label="文件类型"
                align="center">
                </el-table-column>
                <el-table-column
                prop="suffix"
                 width="100"
                label="置顶顺序"
                align="center">
                    <template slot-scope="scope"> 
                        {{scope.row.systemSort ? scope.row.systemSort : '无'}}
                    </template>
                </el-table-column>
                <el-table-column
                label="创建时间"
                align="center">
                    <template slot-scope="scope">
                        {{scope.row.createDate | date}}
                    </template>
                </el-table-column>
                <el-table-column
                label="操作"
                width="100"
                align="center">
                    <template slot-scope="scope">
                        <el-button v-if='scope.row.systemSort' type="text" size="small" @click="quitFileSort(scope.row)" >取消置顶</el-button>
                        <el-button v-else type="text" size="small" @click="documentFileSort(scope.row)">置顶</el-button>
                        
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <el-pagination
        class="pagebox"
        @current-change="pageChanged"
        :current-page="query.pageNum"
        :page-size="query.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total">
        </el-pagination>

        <el-dialog
            title="文件置顶"
            :append-to-body="true"
            :close-on-click-modal="false" 
            width="500px"
            :visible.sync="dialogVisible">
            <div class="read-dialog">
                请设置置顶顺序：
                <el-input-number v-model="num" :min="1" :max="100" label="描述文字"></el-input-number>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" size="small" @click="onSure">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { documentAPI } from 'liankong-ui-api'
export default {
    data() {
        return {
            query: {
                target: "3",
                keyword: '',
                pageNum: 1,
                pageSize: 10
            },
            vkey:'documentLibrary',
            num:1,
            dialogVisible:false,
            currentId:null,
            loading:false
        }       
    },
    computed:{
        viewer(){
            return this.$store.state.manage.views[this.vkey].list || [];
        },
        total(){
            return this.$store.state.manage.views[this.vkey].paging.total;
        }
    },
    created(){
        this.onLoad();
    },
    methods: {
        // 取消置顶
        quitFileSort(row){
            this.loading = true
            documentAPI.cancelDocumentFileSort(row.id).then(res => {
                if(res.code == 0){
                    setTimeout(() => {
                        this.$message.success('操作成功！');
                        this.query.pageNum = 1;
                        this.onLoad()
                    },2000)
                    
                }else{
                    this.$message.error(res.message)
                }
            })
        },
        onSure(){
            this.loading = true
            documentAPI.documentFileSort({
                sort:this.num,
                documentId:this.currentId
            }).then(res => {
                if(res.code == 0){
                    setTimeout(() => {
                        this.$message.success('操作成功！');
                        this.query.pageNum = 1;
                        this.onLoad()
                    },2000)
                    
                }else{
                    this.$message.error(res.message)
                }
                this.dialogVisible = false;
                this.num = 1;
                this.currentId = null
            })
        },
        // 文档排序
        documentFileSort(row){
            this.currentId = row.id
            this.dialogVisible = true;
        },
        onLoad(){
            this.loading = true
            this.$store.dispatch('manage/list', {
                vkey:this.vkey,
                reset:true,
                query: this.query,
                interfaceName: 'getDocumentLibrary'
            }).then(res => {
                this.loading = false
            })
        },
        pageChanged(num){
            this.query.pageNum = num;
            this.onLoad()
        },
        onSubmit() {
            this.query.pageNum =  1;
            this.onLoad();
        },
    }
}
</script>
<style lang="less" scoped>
.logs-form {
    padding: 20px;
}
.table-box {
    margin: 0 20px 0px 20px;
    padding-bottom: 20px;
}
.pagebox {
    padding: 0px 20px 20px 20px;
}
</style>